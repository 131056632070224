/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-alert */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import DatePicker from 'tailwind-datepicker-react';
import { format, intervalToDuration } from 'date-fns';
import classNames from 'classnames';
import { Gallery, Item } from 'react-photoswipe-gallery';
import countryList from 'react-select-country-list';
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import useAuth from '../../contexts/AuthContext/AuthContext';
import client from '../../services/client';
import { useTimer } from '../../components/Accordian/Accordian';
import Button from '../../components/Buttons/Button/Button';
import BaseModal from '../../components/BaseModal/BaseModal';
import HoldButton from '../../components/HoldButton';
import PartnerIcon from '../../assets/Icons/partner-badge.svg';
import 'photoswipe/dist/photoswipe.css';
import Notes from '../../features/Notes';
import EmptyState from '../../components/EmptyState';

const inputClass =
  'py-1.5 px-4 bg-black text-sm rounded border border-grey-3  w-full !ring-0 !outline-none focus-visible:!outline-2 focus-visible:!outline-gold text-white mt-1';

const Moderation = () => {
  const countryOptions = useMemo(() => countryList().getData(), []);
  const { god } = useAuth();
  const [params] = useSearchParams();
  const nav = useNavigate();
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [denyModal, setDenyModal] = useState(false);
  const [denying, setDenying] = useState(false);
  const [denyError, setDenyError] = useState(false);

  const [banModal, setBanModal] = useState(false);
  const [banning, setBanning] = useState(false);
  const [banError, setBanError] = useState(false);
  const [banType, setBanType] = useState('community');
  const [approveModal, setApproveModal] = useState(false);
  const [approving, setApproving] = useState(false);
  const [approveError, setApproveError] = useState(false);

  const [selectedDate, setSelectedDate] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [apt, setApt] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('US');

  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);

  const idRef = useRef();
  const selfieRef = useRef();
  const [idDimensions, setIdDimensions] = useState({ width: 0, height: 0 });
  const [selfieDimensions, setSelfieDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [idRotation, setIdRotation] = useState(0);
  const [selfieRotation, setSelfieRotation] = useState(0);
  const [focusId, setFocusId] = useState(false);
  const [focusSelfie, setFocusSelfie] = useState(false);

  const rotateId = () => {
    if (idRotation === 3) {
      return setIdRotation(0);
    }

    setIdRotation((r) => r + 1);
  };

  const rotateSelfie = () => {
    if (selfieRotation === 3) {
      return setSelfieRotation(0);
    }

    setSelfieRotation((r) => r + 1);
  };

  useEffect(() => {
    if (banModal) {
      setBanType('Community');
    }
  }, [banModal]);

  const uiElements = [
    {
      name: 'custom-rotate-button',
      ariaLabel: 'Rotate',
      order: 9,
      isButton: true,
      html: {
        isCustomSVG: true,
        inner:
          '<path d="M13.887 6.078C14.258 6.234 14.5 6.598 14.5 7V8.517C18.332 8.657 21.258 10.055 23.15 12.367 24.519 14.041 25.289 16.13 25.496 18.409A1 1 0 0123.504 18.591C23.327 16.645 22.68 14.952 21.601 13.633 20.156 11.867 17.831 10.653 14.5 10.517V12A1.002 1.002 0 0112.779 12.693L10.304 10.121A1.002 1.002 0 0110.324 8.713L12.8 6.286A1 1 0 0113.887 6.078ZM7.5 16A1.5 1.5 0 006 17.5V24.5A1.5 1.5 0 007.5 26H17.5A1.5 1.5 0 0019 24.5V17.5A1.5 1.5 0 0017.5 16H7.5Z" id="pswp__icn-rotate"/>',
        outlineID: 'pswp__icn-rotate',
      },
      appendTo: 'bar',
      onClick: (e, el, pswpInstance) => {
        if (!pswpInstance.currSlide?.content.element) {
          return;
        }

        const item = pswpInstance.currSlide.content.element;

        const prevRotateAngle = Number(item.dataset.rotateAngel) || 0;
        const rotateAngle = prevRotateAngle === 270 ? 0 : prevRotateAngle + 90;

        // add slide rotation
        item.style.transform = `${item.style.transform.replace(
          `rotate(-${prevRotateAngle}deg)`,
          ''
        )} rotate(-${rotateAngle}deg)`;
        item.dataset.rotateAngel = String(rotateAngle);
      },
      onInit: (el, pswpInstance) => {
        // remove applied rotation on slide change
        // https://photoswipe.com/events/#slide-content-events
        pswpInstance.on('contentRemove', () => {
          if (!pswpInstance.currSlide?.content.element) {
            return;
          }

          const item = pswpInstance.currSlide.content.element;
          item.style.transform = `${item.style.transform.replace(
            `rotate(-${item.dataset.rotateAngel}deg)`,
            ''
          )}`;
          delete item.dataset.rotateAngel;
        });
      },
    },
  ];

  useEffect(() => {
    client
      .get(`${process.env.REACT_APP_API_URL}/admin/v1/wallets/identity_reasons`)
      .then(({ data }) => setCategories(data));
  }, []);

  useEffect(() => {
    if (items.length) {
      if (params.get('item')) {
        setSelectedId(Number(params.get('item')));
      } else if (params.get('user')) {
        setSelectedId(
          items.find(
            (item) => item.user_profile.id === Number(params.get('user'))
          )?.id
        );
      } else {
        nav(`/godmode/moderation?item=${items[0].id}`);
      }
    }
  }, [params, items]);

  const clearInputs = () => {
    setIdNumber('');
    setFirstName('');
    setLastName('');
    setAddress('');
    setApt('');
    setCity('');
    setState('');
    setZip('');
    setCountry('US');
    setSelectedDate('');
  };

  useEffect(() => {
    clearInputs();
  }, [selectedId]);

  const getIdentities = () => {
    setLoading(true);
    setError(false);
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/wallets/pending_identities`
      )
      .then(({ data }) => setItems(data))
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeny = () => {
    setDenying(true);
    setDenyError(false);
    client
      .post(
        `${process.env.REACT_APP_API_URL}/admin/v1/wallets/reject_identity`,
        {
          identity_document_id: selectedId,
          reason_id: category,
        }
      )
      .then(() => {
        clearInputs();
        setApproveModal(false);
        setDenyModal(false);
        getIdentities();
        nav('/godmode/moderation');
      })
      .catch(() => setDenyError(true))
      .finally(() => {
        setDenying(false);
      });
  };

  const selectedItem = items.find((i) => i.id === selectedId);
  const isDisabled = !selectedDate || !idNumber;

  const getPayload = () => ({
    // Required Fields
    identity_document_id: selectedId,
    dob: format(new Date(selectedDate), 'yyyy-MM-dd'),
    document_identifier: idNumber,
    // Optional Fields
    first_name: firstName || undefined,
    last_name: lastName || undefined,
    address_line_1: address || undefined,
    address_line_2: apt || undefined,
    zip_code: zip || undefined,
    country_code: country || undefined,
    state: state || undefined,
    city: city || undefined,
  });

  const handleBan = async () => {
    try {
      setBanning(true);
      setBanError(false);
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/wallets/approve_identity`,
        {
          ...getPayload(),
          ban: true,
        }
      );
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/users/${selectedItem.user_profile.id}/disable`,
        {
          type: banType,
          reason: 'Information mismatch',
          expires_at: null,
        }
      );
      setIdNumber('');
      setSelectedDate(null);
      setBanModal(false);
      getIdentities();
      nav('/godmode/moderation');
    } catch (e) {
      setBanError(true);
    } finally {
      setBanning(false);
    }
  };

  const handleApprove = () => {
    setApproving(true);
    setApproveError(false);
    client
      .post(
        `${process.env.REACT_APP_API_URL}/admin/v1/wallets/approve_identity`,
        {
          ...getPayload(),
          document_identifier: idNumber,
        }
      )
      .then(() => {
        clearInputs();
        setApproveModal(false);
        setApproveModal(false);
        getIdentities();
        nav('/godmode/moderation');
      })
      .catch(() => setApproveError(true))
      .finally(() => {
        setApproving(false);
      });
  };

  useEffect(() => {
    getIdentities();
  }, []);

  if (Object.keys(god).length === 0) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }

  return (
    <Layout showHeaderElements userId={god.id} className="md:px-0 text-grey-2">
      <div className=" items-stretch flex h-[calc(100vh-82px)]">
        <div className="p-4 border-r border-grey-4 overflow-auto h-full w-[240px] sm:w-[300px]">
          {loading && <Loader />}
          {error && (
            <p className="text-center text-warning">
              There was an error fetching the list of items
            </p>
          )}
          {items.map((item, i) => (
            <IdItem
              key={`${item.id}_${i}`}
              id={item.id}
              imageUrl={item.user_profile.profile_image_url}
              username={item.user_profile.username}
              createdAt={new Date(item.created_at)}
              selected={item.id === selectedId}
              userId={item.user_profile.id}
            />
          ))}
          {!loading && !items.length && !error && <p>Nothing to review here</p>}
        </div>
        <div className="flex-1 flex flex-col">
          {selectedItem ? (
            <>
              <div className="flex-1 p-4 w-full max-w-7xl mx-auto ">
                <h3 className="font-extrabold text-white text-2xl mb-4">
                  {selectedItem.user_profile.username}
                  <small className="text-xs block">
                    (Click images to zoom)
                  </small>
                </h3>
                <div
                  className={classNames(
                    'grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 sticky top-0 bg-black max-h-[40vh] overflow-auto',
                    {
                      '!grid-cols-1': focusSelfie || focusId,
                    }
                  )}
                >
                  <Gallery uiElements={uiElements} withCaption>
                    {idDimensions.width && !focusSelfie && (
                      <Item
                        original={selectedItem.id_image_url}
                        thumbnail={selectedItem.id_image_url}
                        width={String(idDimensions.width)}
                        height={String(idDimensions.height)}
                        caption="ID"
                      >
                        {({ ref, open }) => (
                          <div className="rounded-2xl border p-4 border-grey-4 w-full">
                            <p className="text-2xl font-extrabold text-white pt-2 mb-2">
                              ID <br />
                              <div className="flex gap-2">
                                <Button
                                  variant="tertiary"
                                  text="Rotate"
                                  className="mb-2"
                                  onClick={rotateId}
                                />
                                <Button
                                  variant="tertiary"
                                  text={focusId ? 'Unfocus' : 'Focus'}
                                  className="mb-2"
                                  onClick={() => setFocusId(!focusId)}
                                />
                              </div>
                            </p>
                            <img
                              ref={ref}
                              onClick={open}
                              className="cursor-pointer"
                              src={selectedItem.id_image_url}
                              style={{
                                transform: `rotate(${idRotation * 90}deg)`,
                              }}
                            />
                          </div>
                        )}
                      </Item>
                    )}
                    {selfieDimensions.width && !focusId && (
                      <Item
                        original={selectedItem.selfie_image_url}
                        thumbnail={selectedItem.selfie_image_url}
                        width={String(selfieDimensions.width)}
                        height={String(selfieDimensions.height)}
                        caption="Selfie & ID"
                      >
                        {({ ref, open }) => (
                          <div className="rounded-2xl border p-4 border-grey-4 w-full">
                            <p className="text-2xl font-extrabold text-white pt-2">
                              Selfie &amp; ID <br />
                              <div className="flex gap-2 mb-2">
                                <Button
                                  variant="tertiary"
                                  text="Rotate"
                                  className="mb-2"
                                  onClick={rotateSelfie}
                                />
                                <Button
                                  variant="tertiary"
                                  text={focusSelfie ? 'Unfocus' : 'Focus'}
                                  className="mb-2"
                                  onClick={() => setFocusSelfie(!focusSelfie)}
                                />
                              </div>
                            </p>
                            <img
                              ref={ref}
                              onClick={open}
                              className="cursor-pointer z-10"
                              src={selectedItem.selfie_image_url}
                              style={{
                                transform: `rotate(${selfieRotation * 90}deg)`,
                              }}
                            />
                          </div>
                        )}
                      </Item>
                    )}
                  </Gallery>
                </div>
                <label className="block mb-2">
                  <span className="text-xs font-bold text-white uppercase">
                    ID Number*
                  </span>
                  <input
                    className={inputClass}
                    value={idNumber}
                    onChange={(e) => setIdNumber(e.target.value)}
                  />
                </label>
                <label className="block mb-2">
                  <span className="text-xs font-bold text-white uppercase">
                    Date of Birth*
                  </span>
                  <input
                    style={{ colorScheme: 'light' }}
                    type="date"
                    className={inputClass}
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                </label>
                <div className="grid grid-cols-2 mb-2 gap-4">
                  <label className="block">
                    <span className="text-xs font-bold text-white uppercase">
                      First Name
                    </span>
                    <input
                      className={inputClass}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </label>
                  <label className="block">
                    <span className="text-xs font-bold text-white uppercase">
                      Last Name
                    </span>
                    <input
                      className={inputClass}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </label>
                </div>

                <label className="block mb-2">
                  <span className="text-xs font-bold text-white uppercase">
                    Address
                  </span>
                  <input
                    className={inputClass}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </label>
                <label className="block mb-2">
                  <span className="text-xs font-bold text-white uppercase">
                    Apartment, suite, etc.
                  </span>
                  <input
                    className={inputClass}
                    value={apt}
                    onChange={(e) => setApt(e.target.value)}
                  />
                </label>
                <div className="grid grid-cols-2 mb-2 gap-4">
                  <label className="block">
                    <span className="text-xs font-bold text-white uppercase">
                      City
                    </span>
                    <input
                      className={inputClass}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </label>
                  <label className="block">
                    <span className="text-xs font-bold text-white uppercase">
                      State/Province
                    </span>
                    <input
                      className={inputClass}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </label>
                </div>
                <div className="grid grid-cols-2 mb-2 gap-4">
                  <label className="block">
                    <span className="text-xs font-bold text-white uppercase">
                      Zip/Postal Code
                    </span>
                    <input
                      className={inputClass}
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </label>
                  <label className="block">
                    <span className="text-xs font-bold text-white uppercase">
                      Country
                    </span>
                    <select
                      className={inputClass}
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <optgroup label="Select a Country">
                        {countryOptions.map((c, index) => (
                          <option key={index.toString()} value={c.value}>
                            {c.label}
                          </option>
                        ))}
                      </optgroup>
                    </select>
                  </label>
                </div>
                <div className="flex items-center justify-center my-8 ">
                  <p className="font-bold text-whtie text-center text-xs border border-grey-4 p-4 rounded-lg">
                    NOTE: If a user&apos;s ID submission is approved and
                    it&apos;s determined that the user is underage, the user
                    will be banned from our application.
                  </p>
                </div>
              </div>
              <div className="p-4 border-t border-grey-4">
                <div className="max-w-7xl mx-auto flex items-center justify-end gap-4">
                  <div className="flex-1">
                    <Button
                      variant="grey"
                      onClick={() => setBanModal(true)}
                      className="!text-warning"
                      text="VERIFY AND BAN"
                      disabled={isDisabled}
                      rounded
                      large
                    />
                  </div>
                  <Button
                    variant="warning"
                    onClick={() => setDenyModal(true)}
                    text="DENY"
                    rounded
                    large
                  />
                  <Button
                    variant="primary"
                    onClick={() => setApproveModal(true)}
                    text="VERIFY ID IS AUTHENTIC"
                    disabled={isDisabled}
                    rounded
                    large
                  />
                </div>
              </div>
              {selectedItem && (
                <div className="hidden">
                  <img
                    ref={idRef}
                    className="cursor-pointer"
                    src={selectedItem.id_image_url}
                    onLoad={({ target }) => {
                      setIdDimensions({
                        width: target.width,
                        height: target.height,
                      });
                    }}
                  />
                  <img
                    ref={selfieRef}
                    className="cursor-pointer"
                    src={selectedItem.selfie_image_url}
                    onLoad={({ target }) => {
                      setSelfieDimensions({
                        width: target.width,
                        height: target.height,
                      });
                    }}
                  />
                </div>
              )}
              <BaseModal
                modalIsOpen={approveModal}
                closeModal={() => setApproveModal(false)}
                height="500px"
              >
                <div className="pt-8">
                  <img
                    src={selectedItem.id_image_url}
                    alt="ID"
                    className="bg-grey-4 w-full mb-8 rounded-xl max-h-[240px] object-contain"
                  />
                  <h3 className="text-white font-extrabold text-3xl text-center mb-12">
                    Confirm ID Is Authentic
                  </h3>
                  <p className="text-grey-2 text-center text-sm mb-2">
                    THIS CANNOT BE UNDONE
                  </p>
                  {approveError && (
                    <p className="text-center mb-2 text-warning">
                      There was an error with approving
                    </p>
                  )}
                  <HoldButton
                    variant="primary"
                    text="Click And Hold To Confirm"
                    timeout={200}
                    onConfirm={handleApprove}
                    disabled={approving}
                  />
                </div>
              </BaseModal>
              <BaseModal
                modalIsOpen={banModal}
                closeModal={() => setBanModal(false)}
                height="500px"
              >
                <div className="pt-8">
                  <img
                    src={selectedItem.id_image_url}
                    alt="ID"
                    className="bg-grey-4 w-full mb-8 rounded-xl max-h-[240px] object-contain"
                  />
                  <h3 className="text-white font-extrabold text-3xl text-center  capitalize">
                    Ban {selectedItem.user_profile.username}?
                  </h3>
                  <p className="mb-4 text-grey-2">
                    Confirm ID does not match the information on file for
                    account
                  </p>
                  <select
                    className="w-full rounded-full bg-grey-3 text-white py-2 px-3 mb-12"
                    onChange={(e) => setBanType(e.target.value)}
                  >
                    <option value="community">Community</option>
                    <option value="dispute">Dispute</option>
                    <option value="cheating">Cheating</option>
                    <option value="duplicate">Duplicate</option>
                    <option value="deleted">Deleted</option>
                    <option value="financial">Financial</option>
                  </select>
                  <p className="text-grey-2 text-center text-sm mb-2">
                    THIS CANNOT BE UNDONE
                  </p>
                  {banError && (
                    <p className="text-center mb-2 text-warning">
                      There was an error with banning
                    </p>
                  )}
                  <HoldButton
                    variant="warning"
                    text="Click And Hold To Ban"
                    timeout={200}
                    onConfirm={handleBan}
                    disabled={banning}
                  />
                </div>
              </BaseModal>
              <BaseModal
                modalIsOpen={denyModal}
                closeModal={() => setDenyModal(false)}
                height="520px"
              >
                <div className="pt-8">
                  <img
                    src={selectedItem.id_image_url}
                    alt="ID"
                    className="bg-grey-4 w-full mb-8 rounded-xl max-h-[240px] object-contain"
                  />
                  <h3 className="text-white font-extrabold text-3xl text-center mb-2">
                    DENY ID VERIFICATION
                  </h3>
                  <p className="text-grey-2 text-center mb-8">
                    MAKE USER RESUBMIT VERIFICATION PHOTOS
                  </p>
                  <p className="text-grey-2 text-center text-sm mb-8">
                    THIS CANNOT BE UNDONE
                  </p>
                  <div className="mb-8">
                    {categories.map((c) => (
                      <button
                        type="button"
                        key={`category_${c.id}`}
                        title={c.title}
                        disabled={category === c.id}
                        className="block bg-grey-4 disabled:bg-white transition-colors w-full rounded mb-3 py-3 px-8 text-left font-bold"
                        style={{ color: '#979797' }}
                        onClick={() => setCategory(c.id)}
                      >
                        {c.category}
                      </button>
                    ))}
                  </div>

                  {denyError && (
                    <p className="text-center mb-2  text-warning">
                      There was an error with denying
                    </p>
                  )}
                  <HoldButton
                    variant="warning"
                    text="Click And Hold To Confirm"
                    timeout={200}
                    onConfirm={handleDeny}
                    disabled={denying}
                    // disabled={denying || !category}
                  />
                </div>
              </BaseModal>
            </>
          ) : (
            <p>No Verification Selected</p>
          )}
        </div>
        <div className="h-full w-[340px] relative overflow-auto">
          {selectedItem ? (
            <Notes userIdOverride={selectedItem.user_profile.id} small />
          ) : (
            <EmptyState text="Select an ID to see notes" />
          )}
        </div>
      </div>
    </Layout>
  );
};

const IdItem = ({ id, imageUrl, username, createdAt, selected, userId }) => {
  const [isPartner, setIsPartner] = useState(false);
  const timer = useTimer(new Date(createdAt).getTime());
  const [formattedTime, setFormattedTime] = useState('');

  useEffect(() => {
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/partners/registered?user_id=${userId}`
      )
      .then(({ data }) => setIsPartner(data.registered));
  }, [userId]);

  useEffect(() => {
    try {
      const duration = intervalToDuration({
        start: new Date(createdAt).getTime(),
        end: new Date().getTime(),
      });
      const zeroPad = (num) => String(num).padStart(2, '0');

      setFormattedTime(
        [duration.days, duration.hours, duration.minutes, duration.seconds]
          .filter((t, i) => (i === 2 || i === 3 ? true : Boolean(t)))
          .map(zeroPad)
          .join(':')
      );
    } catch (e) {
      setFormattedTime(format(new Date(createdAt), 'yyyy-MM-dd HH:mm'));
    }
  }, [timer]);

  const over15 =
    new Date().getTime() - new Date(createdAt).getTime() >= 1000 * 60 * 15;

  return (
    <Link
      to={`/godmode/moderation?item=${id}`}
      className={classNames(
        'bg-grey-4 rounded-xl block p-4 hover:opacity-75 ease-in-out mb-4 relative',
        {
          '!bg-grey-3 pointer-events-none': selected,
        }
      )}
    >
      <div className="flex items-center gap-4 overflow-hidden">
        <img
          src={imageUrl}
          alt="user avatar"
          className="w-[36px] h-[36px] rounded-full object-contain"
        />
        <p className="flex-1 text-xl font-extrabold text-white overflow-hidden">
          <span className="block break-words leading-none">{username}</span>
        </p>
      </div>
      {isPartner && (
        <img
          src={PartnerIcon}
          alt="partner"
          className="absolute bottom-1.5 right-1.5"
        />
      )}
      <p className={classNames('font-bold pt-2', { 'text-warning': over15 })}>
        {formattedTime}
      </p>
    </Link>
  );
};

export default Moderation;
