/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import { Popover, Transition } from '@headlessui/react';
import { usePrevious } from 'react-use';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { addMinutes, isAfter } from 'date-fns';
import client from '../../services/client';
import PlayByPlay from './PlayByPlay';
import ScoreCard from './ScoreCard';
import DownIcon from '../../assets/Icons/chevron.svg';
import ScoreOptions from './ScoreOptions';
import { ScoresModal } from '../../features/Matches/MatchCard.tsx';
import Button from '../../components/Buttons/Button/Button';
import DisputeCard from '../../components/DisputeCard/DisputeCard';
import Loader from '../../components/Loader/Loader';
import BaseModal from '../../components/BaseModal/BaseModal';
import HoldButton from '../../components/HoldButton';

const MatchEventManager = ({
  event,
  teams,
  disputes,
  onReplay,
  onComplete,
  onCountdown,
}) => {
  const [blame, setBlame] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [settingScores, setSettingScores] = useState(false);
  const [television, setTelevision] = useState();
  const [scoresData, setScoresData] = useState();
  const [match, setMatch] = useState();
  const [options, setOptions] = useState([]);
  const [round, setRound] = useState(1);
  const [gameMode, setGameMode] = useState(null);
  const [completing, setCompleting] = useState(false);
  const subscription = useRef();

  useEffect(() => {
    if (isAfter(new Date(event.start_date), new Date())) {
      setShowCountdown(true);
    } else {
      setShowCountdown(false);
    }
  }, [event]);

  const handleCountdownComplete = () => {
    onCountdown();
  };

  useEffect(() => {
    if (event.match_event_match_id) {
      import('@rails/actioncable').then(({ createConsumer }) => {
        const cable = createConsumer(process.env.REACT_APP_WEBSOCKET_URL);

        const updateSubscription = cable.subscriptions.create(
          {
            channel: 'MatchRoundsChannel',
            match_id: event.match_event_match_id,
          },
          {
            received(data) {
              setScoresData(data);
            },
          }
        );

        subscription.current = updateSubscription;
      });
    }

    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, [event.match_event_match_id]);

  const resetState = () => {
    setSettingScores(false);
    setTelevision(null);
    setScoresData(null);
    setMatch(null);
    setOptions([]);
    setRound(1);
    setGameMode(null);
  };

  const fetchMatch = (id) =>
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/matches/${id}`)
      .then(({ data }) => {
        setMatch(data);
        const cacheMode = sessionStorage.getItem(
          `game_mode_${data.game_mode_id}`
        );
        if (cacheMode) {
          setGameMode(JSON.parse(cacheMode));
        } else {
          client
            .get(
              `${process.env.REACT_APP_API_URL}/api/v1/game_modes/${data.game_mode_id}`
            )
            .then(({ data }) => {
              sessionStorage.setItem(
                `game_mode_${data.game_mode_id}`,
                JSON.stringify(data)
              );
              setGameMode(data);
            });
        }
      });

  const fetchScores = (id) =>
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/matches/${id}/scores`)
      .then(({ data }) => setScoresData(data));

  const fetchOptions = (id) =>
    client
      .get(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${id}/play_by_plays/options`
      )
      .then(({ data }) => setOptions(data));

  const fetchData = (tvId, matchId) => {
    client
      .get(`${process.env.REACT_APP_API_URL}/api/v1/tv/${tvId}`)
      .then(({ data }) => {
        setTelevision(data);
      });
    fetchScores(matchId);
    fetchOptions(matchId);
    fetchMatch(matchId);
  };

  useEffect(() => {
    if (event.television?.id && event.match_event_match_id) {
      fetchData(event.television.id, event.match_event_match_id);
    } else {
      resetState();
    }
  }, [event.television?.id]);

  const [error, setError] = useState('');

  const maxRound = useMemo(() => {
    if (!scoresData?.length) {
      return 1;
    }
    return Math.max(...scoresData.map((s) => s.round_number));
  }, [scoresData]);

  const team1UUID = useMemo(
    () =>
      television?.television_channel_participants.find((p) =>
        event.teams[0].roster.players.some(
          (player) => player.user_profile.id === p.user_profile.id
        )
      )?.group_identifier,
    [event.teams, television?.television_channel_participants]
  );

  const team2UUID = useMemo(
    () =>
      television?.television_channel_participants.find((p) =>
        event.teams[1].roster.players.some(
          (player) => player.user_profile.id === p.user_profile.id
        )
      )?.group_identifier,
    [event.teams, television?.television_channel_participants]
  );

  const { team1Wins, team2Wins, team1Scores, team2Scores } = useMemo(() => {
    if (event.completed) {
      const { team1Scores, team1Wins } = (
        event.teams[0].match_round_results || []
      ).reduce(
        (prev, curr) => ({
          team1Scores: [...prev.team1Scores, curr],
          team1Wins:
            curr.won && curr.completed ? prev.team1Wins + 1 : prev.team1Wins,
        }),
        { team1Scores: [], team1Wins: 0 }
      );
      const { team2Scores, team2Wins } = (
        event.teams[1].match_round_results || []
      ).reduce(
        (prev, curr) => ({
          team2Scores: [...prev.team2Scores, curr],
          team2Wins:
            curr.won && curr.completed ? prev.team2Wins + 1 : prev.team2Wins,
        }),
        { team2Scores: [], team2Wins: 0 }
      );
      return { team1Scores, team1Wins, team2Scores, team2Wins };
    }
    return (scoresData || []).reduce(
      (prev, curr) => {
        if (curr.team_uuid === team1UUID) {
          return {
            ...prev,
            team1Scores: [...prev.team1Scores, curr],
            team1Wins:
              curr.won && curr.completed ? prev.team1Wins + 1 : prev.team1Wins,
          };
        }
        if (curr.team_uuid === team2UUID) {
          return {
            ...prev,
            team2Scores: [...prev.team2Scores, curr],
            team2Wins:
              curr.won && curr.completed ? prev.team2Wins + 1 : prev.team2Wins,
          };
        }
        return prev;
      },
      { team1Scores: [], team1Wins: 0, team2Scores: [], team2Wins: 0 }
    );
  }, [event, scoresData, team1UUID, team2UUID]);

  const handleStart = async (round, blame) => {
    try {
      const payload = {
        round_number: round,
      };
      if (blame) {
        payload.late_tournament_team_id = blame;
      }
      setError('');
      await client.post(
        `${process.env.REACT_APP_API_URL}//admin/v1/matches/${match.id}/play_by_plays/start_round`,
        payload
      );
      fetchData(event.television?.id, event.match_event_match_id);
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };

  const handleIntervalChange = async () => {
    try {
      setError('');
      await client.post(
        `${process.env.REACT_APP_API_URL}//admin/v1/matches/${match.id}/play_by_plays/play_interval`,
        {}
      );
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };

  const teamHasMajority = useMemo(
    () =>
      team1Wins > event.play_info.num_of_rounds / 2 ||
      team2Wins > event.play_info.num_of_rounds / 2,
    [event, team1Wins, team2Wins]
  );

  const canSubmit = useMemo(
    () =>
      !event.completed &&
      !event.teams.some((t) => t.placement && t.placement > 0) &&
      scoresData?.length > 0 &&
      teamHasMajority,
    [event, scoresData, teamHasMajority]
  );

  const potentialWinner = useMemo(() => {
    const { team1AllWins, team2AllWins } = (scoresData || []).reduce(
      (prev, curr) => {
        if (curr.team_uuid === team1UUID) {
          return {
            ...prev,
            team1AllWins: curr.won ? prev.team1AllWins + 1 : prev.team1AllWins,
          };
        }
        if (curr.team_uuid === team2UUID) {
          return {
            ...prev,
            team2AllWins: curr.won ? prev.team2AllWins + 1 : prev.team2AllWins,
          };
        }
        return prev;
      },
      { team1AllWins: 0, team2AllWins: 0 }
    );
    return (
      team1AllWins > event.play_info.num_of_rounds / 2 ||
      team2AllWins > event.play_info.num_of_rounds / 2
    );
  }, [event, scoresData, team1UUID, team2UUID]);

  const handleFinalize = async () => {
    try {
      const payload = {
        round_number: round,
      };

      if (potentialWinner) {
        payload.final = true;
      }

      await client.post(
        `${process.env.REACT_APP_API_URL}//admin/v1/matches/${match.id}/play_by_plays/finalize_round`,
        payload
      );
      fetchData(event.television?.id, event.match_event_match_id);

      if (round < event.play_info.num_of_rounds && !potentialWinner) {
        setRound(round + 1);
      }
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    }
  };

  const getDisabled = () => {
    const scoresEqual = (scoresData || [])
      .filter((s) => s.round_number === round)
      .every((s, i, arr) => s.score === arr[0].score);

    return round === event.play_info.num_of_rounds && scoresEqual;
  };

  const leader =
    event.teams.length <= 1
      ? undefined
      : event.teams[team1Wins > team2Wins ? 0 : 1];

  const prevSubmit = usePrevious(canSubmit);

  useEffect(() => {
    if (!prevSubmit && canSubmit) {
      setCompleting(true);
    }
  }, [leader, prevSubmit, canSubmit]);

  const uuid = team1Wins > team2Wins ? team1UUID : team2UUID;

  const activeRound = scoresData?.find((s) => s.round_number === round);
  const completed =
    event.completed || event.teams.some((t) => t.placement && t.placement > 0);

  return (
    <div
      key={`event_manager_${event.id}`}
      className="col-span-9 grid grid-cols-12 overflow-hidden"
      style={{ height: 'calc(100vh - 80px)' }}
    >
      <div className="col-span-8 text-white overflow-auto flex flex-col mx-auto max-w-7xl w-full px-4">
        {event.match_event_match_id &&
        !showCountdown &&
        event.state !== 'pending' ? (
          <>
            <div className="flex-1 overflow-auto">
              {completed ? (
                <div className="flex flex-col gap-4 h-full items-center justify-center mx-auto max-w-7xl w-full px-4">
                  <h3 className="font-extrabold text-3xl">Event Completed</h3>
                  <p className="text-grey-2">
                    You manually update scores below
                  </p>
                </div>
              ) : activeRound?.completed ? (
                <div className="flex flex-col gap-4 h-full items-center justify-center mx-auto max-w-7xl w-full px-4">
                  <h3 className="font-extrabold text-3xl">
                    Game {round} Finalized
                  </h3>
                  <p className="text-grey-2">
                    You manually update scores below
                  </p>
                </div>
              ) : activeRound && television ? (
                <ScoreOptions
                  event={event}
                  options={options}
                  matchId={event.match_event_match_id}
                  onSave={() => fetchScores(event.match_event_match_id)}
                  round={round}
                  scores={scoresData}
                  team1UUID={team1UUID}
                  team2UUID={team2UUID}
                />
              ) : (
                <div className="flex flex-col gap-4 h-full items-center justify-center mx-auto max-w-7xl w-full px-4">
                  <h3 className="font-extrabold text-3xl">
                    Start Game {round}
                  </h3>
                  {error && <p className="text-center text-warning">{error}</p>}
                  <Button
                    large
                    rounded
                    variant="primary"
                    text={`Start Game ${round}`}
                    onClick={() => {
                      if (
                        round === 1 &&
                        isAfter(
                          new Date(),
                          addMinutes(new Date(event.start_date), 10)
                        )
                      ) {
                        setBlame(true);
                      } else {
                        handleStart(round);
                      }
                    }}
                  />
                  <BaseModal
                    height="auto"
                    modalIsOpen={blame}
                    closeModal={() => setBlame(false)}
                  >
                    <div className="text-center pb-4">
                      <h3 className="font-extrabold text-white text-3xl mb-8">
                        Who was late?
                      </h3>
                      <div className="flex flex-col gap-2">
                        {event.teams.map((t) => (
                          <Button
                            text={t.roster.team_name}
                            variant="grey"
                            rounded
                            large
                            className="!bg-grey-3 !text-warning !w-full"
                            onClick={() => handleStart(1, t.roster.id)}
                          />
                        ))}
                        <Button
                          text="1v1Me"
                          variant="grey"
                          rounded
                          large
                          className="!bg-grey-3 !text-warning !w-full"
                          onClick={() => handleStart(1)}
                        />
                      </div>
                      {error && (
                        <p className="pt-4 text-warning text-center text-sm">
                          {error}
                        </p>
                      )}
                    </div>
                  </BaseModal>
                </div>
              )}
            </div>
            <div className="text-grey-2 p-4 border-t border-l border-r -ml-4 border-grey-4 rounded-t-2xl flex items-center justify-between gap-8">
              {event.play_info.num_of_rounds > 1 && !completed ? (
                <Popover className="relative z-10">
                  <Popover.Button className="bg-grey-4 px-4 py-2 rounded-full flex items-center gap-4 hover:opacity-75  transition-opacity ease-in-out !ring-0 !outline-none">
                    Game {round}{' '}
                    <img
                      src={DownIcon}
                      alt="expand"
                      className="w-2 opacity-70 rotate-90"
                    />
                  </Popover.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform translate-y-2 opacity-0"
                    enterTo="transform translate-y-0 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform translate-y-0 opacity-100"
                    leaveTo="transform translate-y-2 opacity-0"
                  >
                    <Popover.Panel className="absolute bottom-8 left-0 z-10 shadow-lg rounded-lg overflow-hidden bg-black w-[300px]">
                      {({ close }) => (
                        <div onClick={() => close()}>
                          {[
                            ...new Array(event.play_info.num_of_rounds).keys(),
                          ].map((_, i) => (
                            <button
                              disabled={
                                i + 1 > maxRound + 1 ||
                                (i > 0 &&
                                  !scoresData?.find((s) => s.round_number === i)
                                    ?.completed)
                              }
                              onClick={() => setRound(i + 1)}
                              type="button"
                              className="block disabled:opacity-50 disabled:pointer-events-none disabled:line-through bg-grey-4 p-2 w-full text-left border-b border-grey-3 hover:opacity-75  transition-opacity ease-in-out !ring-0 !outline-none"
                              key={`round${i + 1}`}
                            >
                              Game {i + 1}
                            </button>
                          ))}
                        </div>
                      )}
                    </Popover.Panel>
                  </Transition>
                </Popover>
              ) : (
                <div />
              )}
              <div className="flex items-center gap-2">
                {event.state === 'live' && event.play_info.play_interval_name !== null && (
                  <Button
                    text={`Next ${event.play_info.play_interval_name.slice(0, -1)}`}
                    variant="primary"
                    rounded
                    large
                    className="!px-8"
                    onClick={() => {
                      const confirmed = window.confirm('Are you sure you want to change the interval?');
                      if (confirmed) {
                        handleIntervalChange();
                      }
                    }}
                  />
                )}
                {event.state === 'review' ? (
                  <p>
                    Match finalized, if you need to override this decision
                    please contact an admin
                  </p>
                ) : (
                  <Button
                    text="Manual Score Entry"
                    variant="grey"
                    rounded
                    large
                    className="!px-6"
                    onClick={() => setSettingScores(true)}
                  />
                )}
                {activeRound && !activeRound?.completed && (
                  <Button
                    text="Finalize Score"
                    variant="primary"
                    disabled={getDisabled()}
                    rounded
                    large
                    className="!px-6"
                    onClick={() => handleFinalize()}
                  />
                )}
                {canSubmit && leader && (
                  <>
                    <Button
                      text="Complete Event"
                      variant="primary"
                      rounded
                      large
                      className="!px-6"
                      onClick={() => setCompleting(true)}
                    />
                    <ConfirmModal
                      isOpen={completing}
                      onClose={() => setCompleting(false)}
                      leader={leader}
                      uuid={uuid}
                      onComplete={onComplete}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-8 items-center justify-center mx-auto max-w-7xl w-full px-4 overflow-hidden">
            <VortexCountdown
              event={event}
              onComplete={handleCountdownComplete}
            />
          </div>
        )}
      </div>
      <div className="col-span-4 text-white overflow-hidden flex flex-col">
        <ScoreCard
          event={event}
          scores={{ team1Wins, team2Wins, team1Scores, team2Scores }}
          host={match?.participants.find((p) => p.type === 'host')?.username}
        />
        <br />
        {disputes.length > 0 && (
          <div className="mb-4">
            <h3 className="text-white font-bold text-2xl">Disputes</h3>
            {disputes.map(({ dispute, match }) => (
              <DisputeCard
                key={match.id}
                disputeId={match.id}
                participants={match.participants}
                players={dispute.teams}
                matchName={dispute.match_name}
                onReplay={() => onReplay(match.id)}
              />
            ))}
          </div>
        )}
        <br />
        {event.match_event_match_id && (
          <PlayByPlay
            event={event}
            matchId={event.match_event_match_id}
            currRound={scoresData?.find((s) => !s.completed)?.round_number}
          />
        )}
      </div>
      {gameMode && event.match_event_match_id && match && (
        <ScoresModal
          currRound={
            scoresData?.find((s) => !s.completed)?.round_number ||
            scoresData?.[scoresData.length - 1]?.round_number
          }
          isOpen={settingScores}
          onClose={() => setSettingScores(false)}
          scoreType={gameMode.round_score_type}
          numRounds={event.play_info.num_of_rounds}
          matchId={event.match_event_match_id}
          teams={(teams || [])
            .map((t) => {
              const found = match.participants.find((p) => {
                const player = t.tournament_players.find(
                  (tp) => tp.user_profile.id === p.user_id
                );
                return player;
              });

              return found
                ? {
                    ...t,
                    team_uuid: found.team_uuid,
                  }
                : null;
            })
            .filter(Boolean)}
          error={error}
          onSave={() => {
            fetchScores(event.match_event_match_id);
          }}
          onDelete={() => {
            fetchScores(event.match_event_match_id);
          }}
          hideFinalize
        />
      )}
    </div>
  );
};

const VortexCountdown = ({ onComplete, event }) => (
  <div className="py-16 lg:py-32 mb-16 relative mt-4 sm:mt-8 w-full">
    <img
      src="https://1v1me.com/Images/vortex.gif"
      alt="Vortex"
      className="absolute top-0 left-0 w-full h-full object-cover object-top z-0 opacity-5 rounded-2xl overflow-hidden"
    />
    <div
      style={{
        WebkitTransformStyle: 'preserve-3d',
        WebkitTransform: 'translate3d(0, 0, 0)',
      }}
      className="absolute top-0 left-0 w-full h-full object-cover object-center blur-2xl z-10 opacity-40 rounded-2xl overflow-hidden pointer-events-none"
    >
      <img
        src={
          event.wide_image_url ||
          event.play_info.game_landscape_image_url ||
          'https://1v1me.com/Images/holder-stake.png'
        }
        className="w-full h-full object-cover object-center"
        alt="banner"
      />
    </div>

    <div className="relative z-10 max-w-7xl mx-auto">
      <p className="text-white text-xs font-bold text-center uppercase tracking-[6px]">
        Starting In
      </p>
      <p className="text-gold font-[900] text-5xl md:text-6xl 2xl:text-[100px] text-center leading-none">
        <Countdown
          date={new Date(event.start_date)}
          onComplete={onComplete}
          renderer={(cd) => (
            <>
              {cd.days > 0 && `${cd.formatted.days}:`}
              {cd.hours > 0 && `${cd.formatted.hours}:`}
              {cd.formatted.minutes}:{cd.formatted.seconds}
            </>
          )}
        />
      </p>
    </div>
  </div>
);

const ConfirmModal = ({ isOpen, onClose, leader, uuid, onComplete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setError('');
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/submit`,
        {
          team_uuid: uuid,
          placement: 1,
        }
      );
      onComplete();
      onClose();
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseModal
      modalIsOpen={isOpen}
      closeModal={onClose}
      header=""
      fullWidth={false}
      large
    >
      <div className="sm:px-12 pt-12 h-full flex flex-col items-center justify-center ">
        <img
          src={
            leader.roster.players.find(
              (p) =>
                p.type === 'leader' || p.type === 'TournamentPlayer::Leader'
            )?.user_profile.profile_image_url ||
            leader.roster.players[0].user_profile.profile_image_url
          }
          alt="player"
          className="w-[64px]  h-[64px]  object-cover rounded-full"
        />
        <h3 className="font-extrabold text-white text-3xl pt-4 mb-2 flex-1 text-center">
          Confirm {leader.roster.team_name} Won
        </h3>
        <div className="max-w-lg mx-auto">
          {error && (
            <p className="py-2 text-warning text-center text-sm">{error}</p>
          )}
          <p className="text-grey-2 text-sm mb-4 text-center">
            THIS CANNOT BE UNDONE AND WILL PAYOUT ANY STAKERS
          </p>
          <div className="">
            <HoldButton
              variant="warning"
              text="Hold To Confirm"
              timeout={250}
              onConfirm={handleSubmit}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default MatchEventManager;
