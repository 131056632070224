/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Buttons/Button/Button';
import SlideOut from '../../components/SlideOutContent/SlideOutContent';
import Modal from '../../components/BaseModal/BaseModal';
import ClipsList from '../../features/ClipsList/ClipsList';
import ChatFeed from '../../components/Chat/ChatFeed/ChatFeed';
import TeamsList, { Team } from '../../features/TeamsList/TeamsList';
import DisputeCard from '../../components/DisputeCard/DisputeCard';
import Bracket from '../../features/Brackets/Brackets';

import { formatBrackets } from '../../utils/formatBrackets';

import useAuth from '../../contexts/AuthContext/AuthContext';
import useTournament from '../../contexts/TournamentsContext/TournamentsContext';

import RulesImage from '../../assets/Icons/rules.svg';
import RulesWhiteImage from '../../assets/Icons/rules-white.svg';
import PrizesImage from '../../assets/Icons/prizes.svg';
import BracketsImage from '../../assets/Icons/bracket.svg';
import firstPlaceIcon from '../../assets/Icons/1-icon.svg';
import secondndPlaceIcon from '../../assets/Icons/2-icon.svg';
import thirdPlaceIcon from '../../assets/Icons/3-icon.svg';
import MatchCard from '../../features/Matches/MatchCard.tsx';
import client from '../../services/client';
import { Jersey } from './UpdateTournament';
import MatchEventManager from './MatchEventManager';
import BracketV2 from '../../features/BracketV2';

const TournamentsManager = () => {
  const {
    // loading,
    isMore,
    teams,
    tournament,
    event,
    getEvent,
    tournamentBracket,
    rules,
    gameMode,
    comments,
    clips,
    disputes,
    getTeams,
    getTournament,
    getTournamentBracket,
    initBracketSocket,
    getRulesSet,
    getGameMode,
    getComments,
    getMoreMessages,
    getClips,
    getDisputes,
    disqualifyTeam,
    deleteComment,
    silenceUser,
    submitMatchPlacement,
    matches,
    getMatches,
    setEvent,
  } = useTournament();
  const { tournamentId } = useParams();
  const { god } = useAuth();

  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [isPrizesOpen, setIsPrizesOpen] = useState(false);
  const [isBracketOpen, setIsBracketOpen] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [openUserModal, setUserModal] = useState(false);
  const [announcementModal, setAnnouncementModal] = useState(false);
  const [teamToDQ, setTeamToDQ] = useState({});
  const [user, setUser] = useState({});
  const [currentNode, setCurrentNode] = useState();
  const [bottomScroll] = useState();
  const [slideOutWidth, setSlideOutWidth] = useState();
  const [slideOutHeight, setSlideOutHeight] = useState();
  const [error, setError] = useState('');

  const sideInfoRef = useRef();
  const disputeRef = useRef();

  useEffect(() => {
    getEvent(tournamentId);
    getTournament(tournamentId);
    getMatches(tournamentId);
    const interval = setInterval(() => {
      getMatches(tournamentId);
    }, 10000);
    const eventInterval = setInterval(() => {
      getEvent(tournamentId);
    }, 10000);
    return () => {
      clearInterval(interval);
      clearInterval(eventInterval);
    };
  }, []);

  useEffect(() => {
    if (tournament !== undefined) {
      getRulesSet(tournament.element_set_id);
      if (tournament.comment_id) {
        getComments(tournament.comment_id, tournament.id);
      }
      getClips(tournament.id);
      getDisputes(tournament.id);
      getTeams(tournament.id);
    }
    if (
      tournament !== undefined &&
      tournament.type !== 'Tournament::MatchEvent' &&
      Object.keys(tournamentBracket).length === 0
    ) {
      getTournamentBracket(tournament.id);
      initBracketSocket(tournament.id);
    }
  }, [tournament]);

  const handleJersey = async (color) => {
    setTeamToDQ((t) => ({ ...t, jersey_color: color }));
    try {
      setError('');
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/events/jersey`,
        {
          event_team_id: teamToDQ.id,
          color,
        }
      );
      getEvent(tournamentId);
      getTeams(tournamentId);
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    if (rules !== undefined) {
      getGameMode(rules.game_mode_id);
    }
  }, [rules]);

  const handleOpenTeamDQModal = (team) => {
    setTeamToDQ(team);
    setOpenTeamModal(true);
  };

  const handleOpenUserModal = (messageObject) => {
    setUser(messageObject);
    setUserModal(true);
  };

  const disqualifyTeamPopUp = () => {
    // eslint-disable-next-line no-alert
    const res = window.confirm('Are you sure!?');
    if (res) {
      disqualifyTeam(tournamentId, teamToDQ.id);
      setOpenTeamModal(false);
    }
  };

  const deleteCommentFromChat = () => {
    deleteComment(user.messageId);
    setUserModal(false);
  };

  const silenceUserForDay = () => {
    silenceUser(user.userId);
    setUserModal(false);
  };

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setCurrentNode(node);
      node.scrollTop = node.scrollHeight;
    }
  }, []);

  const onScroll = () => {
    const { scrollTop } = currentNode;

    if (scrollTop === 0 && isMore) {
      getMoreMessages();
      currentNode.scrollTo(0, 5);
    }
  };

  const handleReplay = async (matchId) => {
    try {
      const res = window.confirm('Are you sure!?');
      if (res) {
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/replay`
        );

        getMatches(tournamentId);
        getDisputes(tournamentId);
      }
    } catch (e) {
      alert(e.response?.data?.message || 'Something went wrong');
    }
  };

  const handlePausePayout = async () => {
    try {
      setError('');
      const res = window.confirm('Are you sure!?');
      if (res) {
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/block_payout`
        );

        getTournament(tournamentId);
      }
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };
  const handlePayoutNow = async () => {
    try {
      setError('');
      const res = window.confirm('Are you sure!?');
      if (res) {
        await client.post(
          `${process.env.REACT_APP_API_URL}/admin/v1/tournaments/${tournamentId}/complete_payout`
        );
        getTournament(tournamentId);
      }
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    }
  };

  if (
    Object.keys(god).length === 0 ||
    tournament === undefined ||
    gameMode === undefined ||
    (tournament.type !== 'Tournament::MatchEvent' &&
      tournamentBracket === undefined)
  ) {
    return (
      <div className="mt-32">
        <Loader />
      </div>
    );
  }

  const formattetBracket =
    tournament.type !== 'Tournament::MatchEvent'
      ? formatBrackets(tournamentBracket, teams)
      : {};

  return (
    <Layout userId={god.id} showHeaderElements>
      <SlideOut
        isOpen={isPrizesOpen}
        handleOpenSlider={() => setIsPrizesOpen(false)}
        setSlideOutWidth={(width) => setSlideOutWidth(width)}
        setSlideOutHeight={(height) => setSlideOutHeight(height)}
      >
        <h2 className="text-white text-3xl font-bold">Prizes</h2>
        <div className="mt-3">
          {tournament.prizes.map((prize, i) => (
            <div
              key={prize.title}
              className="flex items-center mt-3 border-grey-3 border rounded-xl p-2"
            >
              {i === 0 && (
                <img src={firstPlaceIcon} alt={prize.title} className="w-10" />
              )}
              {i === 1 && (
                <img
                  src={secondndPlaceIcon}
                  alt={prize.title}
                  className="w-10"
                />
              )}
              {i === 2 && (
                <img src={thirdPlaceIcon} alt={prize.title} className="w-10" />
              )}
              <div className="ml-3">
                <h3 className="text-white font-bold">{prize.title}</h3>
                <p className="text-grey-2 text-sm">{prize.description}</p>
              </div>
            </div>
          ))}
        </div>
      </SlideOut>
      <SlideOut
        isOpen={isRulesOpen}
        handleOpenSlider={() => setIsRulesOpen(false)}
        setSlideOutWidth={(width) => setSlideOutWidth(width)}
        setSlideOutHeight={(height) => setSlideOutHeight(height)}
      >
        <div
          className="mt-3 scrollable-content"
          style={{ height: 'calc(100vh - 120px)' }}
        >
          <h3 className="text-white font-bold text-2xl">{gameMode.title}</h3>
          <p className="text-grey-2 text-sm mt-1">{gameMode.description}</p>
          <div className="flex items-center mt-3 border-grey-3 border rounded-xl p-2">
            <img
              src={rules.match_format.icon_url}
              alt={rules.match_format.title}
              className="w-8"
            />
            <div className="ml-3">
              <h3 className="text-grey-2 text-xs">
                {rules.match_format.title}
              </h3>
              <p className="text-white font-bold text-sm">
                {rules.match_format.description}
              </p>
            </div>
          </div>
          <div className="flex items-center mt-3 border-grey-3 border rounded-xl p-2">
            <img src={gameMode.icon_url} alt={gameMode.id} className="w-8" />
            <div className="ml-3">
              <h3 className="text-grey-2 text-xs">Game Mode</h3>
              <p className="text-white font-bold text-sm">{gameMode.name}</p>
            </div>
          </div>
          {rules.rule_types.map((rule) => (
            <div
              key={rule.id}
              className="flex items-center mt-3 border-grey-3 border rounded-xl p-2"
            >
              <img src={rule.icon_url} alt={rule.id} className="w-8" />
              <div className="ml-3">
                <h3 className="text-grey-2 text-xs">{rule.title}</h3>
                <p className="text-white font-bold text-sm">
                  {rule.rule_option.name}
                </p>
              </div>
            </div>
          ))}
          <a
            href={`https://1v1me.com/tournament-rules/${tournament.element_set_id}`}
            className="flex items-center mt-3 border-grey-3 border rounded-xl p-2"
          >
            <img src={RulesWhiteImage} alt="rules-white" className="w-8" />
            <div className="ml-3">
              <h3 className="text-grey-2 text-xs">Tournament Rules</h3>
              <p className="text-white font-bold text-sm">Click For Details</p>
            </div>
          </a>
        </div>
      </SlideOut>
      {tournament.type !== 'Tournament::MatchEvent' && (
        <Modal
          xl
          className="lg:w-full"
          modalIsOpen={isBracketOpen}
          closeModal={() => setIsBracketOpen(false)}
        >
          <div className="p-8">
            <BracketV2 brackets={tournamentBracket || []} teams={teams || []} />
          </div>
        </Modal>
      )}
      <div className="grid grid-cols-12 2xl:m-auto">
        <div
          className="border-r border-grey-3 pr-4 relative col-span-3"
          style={{ height: 'calc(100vh - 80px)' }}
        >
          <div className="absolute top-0 bottom-0 flex flex-col sm:pr-4 w-full">
            <div className="section">
              <div ref={sideInfoRef} className="scrollable-content pb-3">
                <div className="flex justify-between pt-4 gap-4">
                  <Button
                    className="!flex-1 h-14 hover:bg-grey-3"
                    text="Rules"
                    variant="tertiary"
                    image={RulesImage}
                    onClick={() => setIsRulesOpen(true)}
                  />
                  <Button
                    className="!flex-1 h-14 hover:bg-grey-3"
                    text="Prizes"
                    variant="tertiary"
                    image={PrizesImage}
                    onClick={() => setIsPrizesOpen(true)}
                  />
                </div>
                {!tournament.completed && (
                  <>
                    <div className="flex flex-col justify-between pt-8 gap-4">
                      <Button
                        text={
                          tournament?.lock ? 'Payout Blocked' : 'Block Payout'
                        }
                        variant="tertiary"
                        disabled={tournament?.lock}
                        className="h-14  whitespace-nowrap !bg-warning disabled:opacity-50"
                        onClick={handlePausePayout}
                      />
                      <Button
                        text="Payout Now"
                        variant="tertiary"
                        className="h-14   !text-success whitespace-nowrap mb-8"
                        onClick={handlePayoutNow}
                        disabled={event?.state !== 'review'}
                      />
                      {tournament.type === 'Tournament::MatchEvent' &&
                        event?.match_event_match_id && (
                          <Button
                            text="Make Announcement"
                            variant="tertiary"
                            className="h-14   whitespace-nowrap"
                            onClick={() => setAnnouncementModal(true)}
                          />
                        )}
                    </div>

                    {error && (
                      <p className="pt-2 text-warning text-center text-sm">
                        {error}
                      </p>
                    )}
                  </>
                )}
                {(tournament.type === 'Tournament::Bracket' ||
                  tournament.type === 'bracket') && (
                  <Button
                    className="w-full h-14 mt-3 py-3 hover:bg-grey-3"
                    text="Bracket"
                    variant="tertiary"
                    image={BracketsImage}
                    onClick={() => setIsBracketOpen(true)}
                    disabled={!tournamentBracket?.length}
                  />
                )}
                <div className="mt-6">
                  {tournament.type === 'Tournament::MatchEvent' && event ? (
                    <div>
                      <h2 className="text-white text-2xl font-extrabold mt-6 mb-2 px-6 lg:px-0">
                        Teams
                      </h2>
                      {event.teams.map((t, i) => (
                        <Team
                          team={t}
                          key={`t_${t.roster.team_id}`}
                          onClick={() =>
                            handleOpenTeamDQModal(
                              teams.find((team) => team.id === t.roster.id)
                            )
                          }
                          bg={
                            i === 0
                              ? '#EE2629'
                              : i === 1
                              ? '#2676EE'
                              : undefined
                          }
                        />
                      ))}
                    </div>
                  ) : (
                    <TeamsList
                      completed={tournament.completed}
                      teams={teams}
                      handleOpenTeamDQModal={handleOpenTeamDQModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {tournament.type === 'Tournament::MatchEvent' && event ? (
          <MatchEventManager
            event={event}
            teams={teams}
            disputes={disputes || []}
            submitMatchPlacement={submitMatchPlacement}
            onReplay={(id) => handleReplay(id)}
            onComplete={() => getEvent(tournamentId)}
            onCountdown={() => getEvent(tournamentId)}
          />
        ) : (
          <>
            <div
              className={classNames(
                'mx-3 border-r border-grey-3 relative col-span-4'
              )}
            >
              <h3 className="text-white font-bold text-2xl mb-4">
                Live Matches
              </h3>
              <div className="section">
                <div className="scrollable-content pb-3 pr-4">
                  {matches.map((m) => (
                    <MatchCard
                      match={m}
                      key={m.id}
                      tournament
                      canSetScores
                      onUpdateMatch={() => {
                        getMatches(tournamentId);
                        getDisputes(tournamentId);
                      }}
                      teams={teams
                        .map((t) => {
                          const found = m.participants.find((p) => {
                            const player = t.tournament_players.find(
                              (tp) => tp.user_profile.id === p.user_id
                            );
                            return player;
                          });

                          return found
                            ? {
                                ...t,
                                team_uuid: found.team_uuid,
                              }
                            : null;
                        })
                        .filter(Boolean)}
                    />
                  ))}
                </div>
              </div>
            </div>
            {(tournament.type !== 'Tournament::MatchEvent' ||
              disputes.length > 0) && (
              <div className="mx-3 col-span-4 relative">
                <h3 className="text-white font-bold text-2xl">Live Disputes</h3>
                <div className="absolute flex flex-col w-full">
                  <div className="section">
                    <div
                      ref={disputeRef}
                      className="scrollable-content pb-3 w-full"
                    >
                      {disputes.length ? (
                        disputes.map(({ match, dispute }) => (
                          <DisputeCard
                            key={match.id}
                            disputeId={match.id}
                            participants={match.participants}
                            players={dispute.teams}
                            matchName={dispute.match_name}
                            submitMatchPlacement={submitMatchPlacement}
                            onReplay={() => handleReplay(match.id)}
                          />
                        ))
                      ) : (
                        <div className="flex mt-3">
                          <div className="p-6 rounded-md border-dashed border-2 border-grey-2 w-full">
                            <p className="text-center text-grey-2">
                              No disputes created for this tournament yet
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <Modal
        modalIsOpen={openTeamModal}
        closeModal={() => setOpenTeamModal(false)}
        header={`Team ${teamToDQ.display_team_name}`}
      >
        <div className="mt-6">
          <Jersey
            color={teamToDQ.jersey_color || '#fff'}
            onChange={handleJersey}
          />
          <br />
          {tournament.type !== 'Tournament::MatchEvent' && (
            <Button
              text="Disqualify Team"
              variant="warning"
              className="w-full"
              onClick={disqualifyTeamPopUp}
            />
          )}
        </div>
      </Modal>
      <Modal
        height="200px"
        modalIsOpen={openUserModal}
        closeModal={() => setUserModal(false)}
        header={`@${user.user}`}
      >
        <div className="mt-6">
          <Button
            text="Silence"
            variant="warning"
            className="w-full"
            onClick={silenceUserForDay}
          />
          <Button
            text={`Delete Message "${user.message}"`}
            variant="warning"
            className="w-full mt-3"
            onClick={deleteCommentFromChat}
          />
        </div>
      </Modal>
      {event?.match_event_match_id && (
        <AnnouncementModal
          isOpen={announcementModal}
          onClose={() => setAnnouncementModal(false)}
          matchId={event.match_event_match_id}
        />
      )}
    </Layout>
  );
};

const AnnouncementModal = ({ isOpen, onClose, matchId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue('');
  }, [isOpen]);

  const handleSend = async () => {
    try {
      setLoading(true);
      setError('');
      await client.post(
        `${process.env.REACT_APP_API_URL}/admin/v1/matches/${matchId}/play_by_plays/announcement`,
        {
          description: value,
        }
      );
      onClose();
    } catch (e) {
      setError(e.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      header="Make Announcement"
      modalIsOpen={isOpen}
      closeModal={onClose}
      height="200px"
    >
      <div>
        <textarea
          className="py-3 text-sm text-white bg-grey-4 focus:outline-none px-4 w-full rounded-lg mb-8"
          rows={4}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          placeholder="Enter Text..."
        />
        {error && <p className="text-warning text-center">{error}</p>}
        <Button
          text="Submit"
          variant="primary"
          rounded
          large
          className="!w-full"
          onClick={handleSend}
          isLoading={loading}
          disabled={loading || !value.trim().length}
        />
      </div>
    </Modal>
  );
};

export default TournamentsManager;
